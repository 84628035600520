<template>
  <logo-header-layout align="center" flex>
    <payment-process-card
        :title="$t('message.payment.sale-disabled', {status: status})"
    />
  </logo-header-layout>
</template>
<script>
import LogoHeaderLayout from '../layout/LogoHeaderLayout'
import PaymentProcessCard from '../cards/PaymentProcessCard'

export default {
  components: {LogoHeaderLayout, PaymentProcessCard},
  props: {
    status: String,
  }
}
</script>
